import React from 'react';
import { graphql } from 'gatsby';
import '../styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Components
import LandingPage from '../components/pages/landing-page';

export default (props) => <LandingPage {...props} />;

export const newsQuery = graphql`
  query {
    news: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/news/" }, frontmatter: { locale: { eq: "zh" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            tags
            locale
            date(formatString: "MMM DD, YYYY")
            featured {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
    sliders: markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/slider/index.zh.md" }) {
      frontmatter {
        sliders {
          description
          sliderImg {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
      }
    }
  }
`;
